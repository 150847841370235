import React, { useState, useEffect, useRef, Children } from "react";
import axios from "axios";
import moment from "moment";

import "../Styles/hnd.css";

import MaterialTable from "material-table";
import {
  Table,
  Button as ButtonMT,
  Checkbox,
  Tooltip,
  Spoiler,
  TextInput,
  Button,
  Collapse, Modal, Select
} from "@mantine/core";

import { FaEnvira } from "react-icons/fa";
import { FaPlug } from "react-icons/fa";
import Chart_Line from "../Chart/Chart_Line";

const { REACT_APP_ECLIPSE_API, REACT_APP_ECLIPSE_API_LOCAL } = process.env;

const Page_HeroesNeverDie = () => {
  //  ----------------------------------------------------------------------------------------- database


  const [DB_Data, setDB_Data] = useState([]);
  const get_DB_Data = async (cafe) => {

    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_awesome_pos__alldata__bydate__formated`,
        { cafe }
      )
      .then((res) => {
        console.log("get_awesome_pos__alldata__bydate__formated", res?.data?.result);
        setDB_Data(res?.data?.result)

      });

  };

  const [DB_ZoneConfig_CafeList, setDB_ZoneConfig_CafeList] = useState([]);
  const get_DB_ZoneConfig_CafeList = async (cafe) => {

    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_awesome_pos__cafeslist`,
        { cafe }
      )
      .then((res) => {
        console.log("get_awesome_pos__cafeslist", res?.data);
        setDB_ZoneConfig_CafeList(res?.data)

      });

  };

  //----------------------------------------------------------------------------------------- table column

  let _headerStyle = {
    maxWidth: "2.4rem",
    padding: ".5rem",
  };
  let _cellStyle = {
    maxWidth: "0px",
    padding: "0.5rem",
  };
  let _filterCellStyle = {
    maxWidth: "0px",
    padding: "0.5rem",
  };

  const RenderStatus = ({ p_data, cafe }) => {
    // console.log('RenderStatus', p_data?.[cafe])

    let dateby_day = p_data?.dateby_day
    // 665|D|004, 017, 019, 021|0.12
    let total_min = p_data?.[cafe]?.split('|')?.[0]
    let pczone_info = p_data?.[cafe]?.split('|')?.[1]
    let pcs_info = p_data?.[cafe]?.split('|')?.[2]
    let avg_occupy_rate =
      Number.isNaN(parseFloat(p_data?.[cafe]?.split('|')?.[3])) ? '' :
        (parseFloat(p_data?.[cafe]?.split('|')?.[3]) * 100)?.toFixed(1)

    function getColor(number) {
      if (number >= 0 && number < 10) return '#4c6600'; // Dark Lime (Very Dark)  
      if (number >= 10 && number < 20) return '#669900'; // Dark Olive Green  
      if (number >= 20 && number < 40) return '#80b300'; // Medium Dark Lime  
      if (number >= 40 && number < 60) return '#99cc00'; // Lime Green  
      if (number >= 60 && number < 80) return '#bfff00'; // Bright Lime  
      if (number >= 80 && number < 100) return '#e6ff66'; // Very Bright Lime  
      return '#ffffff'; // Default: White (for out of range)  
    }

    function convertToHoursMinutes(minutes) {
      if (isNaN(minutes) || minutes < 0) return '';

      let h = Math.floor(minutes / 60);
      let m = minutes % 60;

      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
    }




    return (
      <div onClick={() => {
        setSelectedItem({ dateby_day, cafe, pczone_info, pcs_info, total_min, avg_occupy_rate })
        setModal1_Opened(true)
      }}>
        <b>{convertToHoursMinutes(total_min)}</b>
        {/* <br /> */}
        {/* {getColor(avg_occupy_rate)} */}
        <br />
        <div style={{ color: getColor(avg_occupy_rate) }}>{avg_occupy_rate}</div>
        {/* 
      <b>{total_min}</b> */}
      </div>)
  }


  // const convertData1ToData2 = (data1) => {
  //   const dataMap = {};

  //   data1.forEach((item) => {
  //     const day = `${item.dt_day}`; //.split(" ")[1];
  //     const zone = item["pc zone"];
  //     const hours = item["total hour"];

  //     if (!dataMap[zone]) {
  //       dataMap[zone] = {
  //         id: zone,
  //         // color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
  //         data: [],
  //       };
  //     }

  //     dataMap[zone].data.push({
  //       x: day,
  //       y: hours,
  //     });
  //   });

  //   return Object.values(dataMap);
  // };

  const [state, setState] = React.useState({
    columns: [
      // {
      //   title: "!",
      //   field: "total_offline_count",

      //   headerStyle: _headerStyle,
      //   cellStyle: _cellStyle,
      //   filterCellStyle: _filterCellStyle,
      //   render: (rowData) => (
      //     <div>
      //       {rowData?.total_offline_count !== 0
      //         ? `${rowData?.total_offline_count}🚨`
      //         : ""}
      //     </div>
      //   ),
      // },
      {
        title: "date",
        field: "dateby_day",
        headerStyle: {
          minWidth: "150px",
        },
        // render: (rowData) => (
        //   <div>
        //     <Button
        //       variant="subtle"
        //       color={rowData?.total_offline_count !== 0 ? "red" : "lime"}
        //       onClick={() => alert(rowData?.health_tips)}
        //     >
        //       {rowData?.total_offline_count !== 0
        //         ? `${rowData?.total_offline_count}🚨`
        //         : ""}
        //       {rowData?.health_type} ❔
        //     </Button>
        //   </div>
        // ),
      },
      {
        title: "Zone",
        field: "zone",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,

      },
      {
        title: "BBA",
        field: "Gift BBA",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift BBA"
            />
          </div>
        ),
      },
      {
        title: "BBK",
        field: "Gift BBK",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift BBK"
            />
          </div>
        ),
      },
      {
        title: "BA",
        field: "Gift BukitAngsana",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift BukitAngsana"
            />
          </div>
        ),
      },
      {
        title: "CP",
        field: "Gift CherasPerdana",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift CherasPerdana"
            />
          </div>
        ),
      },
      {
        title: "EP",
        field: "Gift EquinePark",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift EquinePark"
            />
          </div>
        ),
      },
      {
        title: "EVE",
        field: "Gift Evesuite",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift Evesuite"
            />
          </div>
        ),
      },
      {
        title: "IMP",
        field: "Gift Impian",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift Impian"
            />
          </div>
        ),
      },
      {
        title: "KJ",
        field: "Gift KajangIndah",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift KajangIndah"
            />
          </div>
        ),
      },
      {
        title: "KB",
        field: "Gift Kepong Baru",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift Kepong Baru"
            />
          </div>
        ),
      },
      {
        title: "KD",
        field: "Gift KotaDamansara",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift KotaDamansara"
            />
          </div>
        ),
      },
      {
        title: "LM",
        field: "Gift LembahMaju",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift LembahMaju"
            />
          </div>
        ),
      },
      {
        title: "MJ",
        field: "Gift Manjalara",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift Manjalara"
            />
          </div>
        ),
      },
      {
        title: "NIR",
        field: "Gift Nirwana",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift Nirwana"
            />
          </div>
        ),
      },
      {
        title: "OUG",
        field: "Gift OUG",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift OUG"
            />
          </div>
        ),
      },
      {
        title: "PI",
        field: "Gift PandanIndah",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift PandanIndah"
            />
          </div>
        ),
      },
      {
        title: "SA",
        field: "Gift ShahAlam",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift ShahAlam"
            />
          </div>
        ),
      },
      {
        title: "SB",
        field: "Gift SGBuloh",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift SGBuloh"
            />
          </div>
        ),
      },
      {
        title: "SD",
        field: "Gift Serdang",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift Serdang"
            />
          </div>
        ),
      },
      {
        title: "SL",
        field: "Gift SungaiLong",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift SungaiLong"
            />
          </div>
        ),
      },
      {
        title: "SM",
        field: "Gift SriManja",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift SriManja"
            />
          </div>
        ),
      },
      {
        title: "ST",
        field: "Gift Suntek",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Gift Suntek"
            />
          </div>
        ),
      },
      {
        title: "PS",
        field: "Redsea PS",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Redsea PS"
            />
          </div>
        ),
      },
      {
        title: "PV12",
        field: "Redsea PV12",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Redsea PV12"
            />
          </div>
        ),
      },
      {
        title: "TBR",
        field: "Redsea TBR",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Redsea TBR"
            />
          </div>
        ),
      },
      {
        title: "WM",
        field: "Redsea Wangsa Maju",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData}
              cafe="Redsea Wangsa Maju"
            />
          </div>
        ),
      },

      {
        title: "pc mac ",
        field: "pc_mac",
        headerStyle: {
          maxWidth: "1px",
        },
      },
    ],
  });

  //----------------------------------- global variable
  const [modal1_Opened, setModal1_Opened] = useState(false);
  const [modal2_Opened, setModal2_Opened] = useState(false);
  const [modal3_Opened, setModal3_Opened] = useState(false);
  const [selectedItem, setSelectedItem] = useState([])

  const [allCafesList, setallCafesList] = useState(null);

  const [selectedCafe, setSelectedCafe] = useState([])
  const newCafeName = useRef(null)


  const [zoneA, setZoneA] = useState(null);
  const [zoneB, setZoneB] = useState(null);
  const [zoneC, setZoneC] = useState(null);
  const [zoneD, setZoneD] = useState(null);
  const [zoneE, setZoneE] = useState(null);

  const newZoneA = useRef(null)
  const newZoneB = useRef(null)
  const newZoneC = useRef(null)
  const newZoneD = useRef(null)
  const newZoneE = useRef(null)

  useEffect(() => {
    get_DB_Data();
  }, []);

  return (
    <div className="hnd_body">
      <h1>Page Cafe POS - Zone Status Reports</h1>
      <br />
      {/* <Chart_Line p_data={dB_Data_Recent_1week__by_cafename} /> */}
      <br />
      <Modal
        centered
        opened={modal1_Opened}
        onClose={() => setModal1_Opened(false)}
        title=""
      >
        <h1>Zone PCs Info</h1>

        {`Date : ${(selectedItem?.dateby_day)}`}
        <br />
        <br />
        {`Cafe : ${selectedItem?.cafe}`}
        <br />
        <br />
        {`Pc Zone : ${selectedItem?.pczone_info}`}
        <br />
        <br />
        {`Total Minutes (Usage) : ${(selectedItem?.total_min)}`}
        <br />
        <br />
        {`PC Total : ${selectedItem?.pcs_info?.split(',')?.length}`}
        <br />
        {`PC List : ${selectedItem?.pcs_info}`}
        <br />
        <br />
        {`Average Occupy Rate  : ${selectedItem?.avg_occupy_rate}`}
        <br />
        <br />
        *** Average Occupy Rat =  total Minutes (Usage) / ( Pc Total * 24 * 60 )  = {`${selectedItem?.total_min} / ${selectedItem?.pcs_info?.split(',')?.length} * 24 * 60`}

      </Modal>

      <Modal
        centered
        opened={modal2_Opened}
        onClose={() => setModal2_Opened(false)}
        title=""
      >
        <h1>Add New Cafe into Zone Info</h1>
        <TextInput label='New Cafe Name (exctly case-sensitive & spacing as at b3_mon config)'
          // value={zoneA}
          //  placeholder={zoneA} 
          onChange={(event) => newCafeName.current = (event.currentTarget.value)} />
        <br />
        <br />
        <Button
          variant="outline"
          color="orange"
          onClick={() => {
            if (newCafeName.current === null) {
              alert('cafe name cant be empty')
              return
            }
            if (window.confirm('all info correct?')) {
              alert('updated' + newCafeName.current)
            }
          }}
        >
          Create New Cafe Name
        </Button>

      </Modal>

      <Modal
        centered
        opened={modal3_Opened}
        onClose={() => setModal3_Opened(false)}
        title=""
      >
        <h1> Edit PC Zone Info</h1>
        <Select value={selectedCafe} onChange={setSelectedCafe} data={(DB_ZoneConfig_CafeList)?.map(item => ({
          value: item.cafe,
          label: item.cafe
        }))} />
        <br />
        <br />

        <TextInput label='Zone A' value={zoneA} placeholder={zoneA} onChange={(event) => newZoneA.current = (event.currentTarget.value)} />
        <TextInput label='Zone B' value={zoneB} placeholder={zoneB} onChange={(event) => newZoneB.current = (event.currentTarget.value)} />
        <TextInput label='Zone C' value={zoneC} placeholder={zoneC} onChange={(event) => newZoneC.current = (event.currentTarget.value)} />
        <TextInput label='Zone D' value={zoneD} placeholder={zoneD} onChange={(event) => newZoneD.current = (event.currentTarget.value)} />
        <TextInput label='Zone E' value={zoneE} placeholder={zoneE} onChange={(event) => newZoneE.current = (event.currentTarget.value)} />

        <br />
        <br />
        <Button
          variant="outline"
          color="orange"
          onClick={() => {
            if (window.confirm('all info correct?')) {
              alert('updated')
            }
          }}
        >
          Update
        </Button>

      </Modal>

      <MaterialTable
        title={
          <>
            <Button
              variant="outline"
              color="orange"
              onClick={() => {
                get_DB_Data();
              }}
            >
              Reload Data
            </Button>

            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setModal2_Opened(true);
              }}
            >
              Add New Cafe into Zone Info
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                get_DB_ZoneConfig_CafeList()

                setModal3_Opened(true);
              }}
            >
              Edit PC Zone Info
            </Button>
          </>
        }
        columns={state.columns}
        data={DB_Data}
        style={{
          color: "white",
          background: "rgba(36, 43, 38, .5)",
          // backgroundColor: "rgba(0,0,0,.1)",
          // backgroundColor:
          //   "linear-gradient(90deg,rgba(25, 30, 26,.3) .25%, rgba(0, 0, 0,.5) .25%)",
          // backdropFilter: "blur( 11.0px )",
          // "&::webkit-backdrop-filter": {
          //   webkitBackdropFilter: "blur( 18.0px )",
          // },
        }}
        options={{
          exportButton: true,
          padding: "dense",
          headerStyle: {
            background: "rgba(54, 64, 56,.5)",
            border: "none",
          },
          rowStyle: (rowData) => ({
            // backgroundImage:
            // getDarkGradient(rowData?.zone) ,
            // borderBottom: `2px solid ${getDarkColor(rowData?.zone)}`,
            // paddingBottom: '5px',
            // 
            color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
            // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
            fontSize: 12,
          }),
          filtering: true,
          grouping: true,
          exportAllData: true,
          pageSize: 100,
          pageSizeOptions: [20, 50, 100, 200, 500, 1000],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
        }}
        // onRowClick={(evt, selectedRow) => {
        // console.log(selectedRow.id);
        // setSelected_Item(selectedRow);
        // handleClickOpen_1_1();
        // setSelected_Input_OrderId(selectedRow.order_id);
        // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
        // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
        // }}
        detailPanel={
          [
            // {
            //   tooltip: "Show Hardware Changes Log",
            //   render: (rowData) => {
            //     // console.log("rowData", rowData);
            //     return (
            //       <div>
            //         <Comp_CafePC_PCHardware_Log_v2
            //           cafe={rowData?.cafe}
            //           pc_name={rowData?.PC_Name}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]
        }
        actions={
          [
            // {
            //   icon: "save",
            //   tooltip: "Save User",
            //   onClick: (event, rowData) => {
            //     // get_DB_CafeHardware_StatusLog_byCafePCName(
            //     //   rowData?.cafe,
            //     //   rowData?.PC_Name
            //     // );
            //     setSelectedRow(rowData);
            //     setDialog_Show(true);
            //     console.log("rowData", rowData);
            //   },
            // },
          ]
        }
        components={
          {
            // Action: (props) => (
            //   <Button
            //     // disabled
            //     onClick={(event) => props.action.onClick(event, props.data)}
            //     color="inherit"
            //     variant="text"
            //     style={{ textTransform: "none" }}
            //     size="large"
            //   >
            //     <div style={{ fontSize: "2rem" }}>🚧</div>
            //   </Button>
            // ),
          }
        }
      />
    </div>
  );
};

export default Page_HeroesNeverDie;
