import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "@mantine/core";

// import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

const Chart_Line = ({ p_data }) => {
  // const data = [
  //   {
  //     id: "Zone A",
  //     color: "hsl(298, 70%, 50%)",
  //     data: [
  //       {
  //         x: "mon",
  //         y: 114,
  //       },
  //       {
  //         x: "tue",
  //         y: 93,
  //       },
  //       {
  //         x: "wed",
  //         y: 14,
  //       },
  //       {
  //         x: "thu",
  //         y: 59,
  //       },
  //     ],
  //   },
  //   {
  //     id: "Zone B",
  //     color: "hsl(287, 70%, 50%)",
  //     data: [
  //       {
  //         x: "tue",
  //         y: 51,
  //       },
  //       {
  //         x: "wed",
  //         y: 146,
  //       },
  //       {
  //         x: "thu",
  //         y: 53,
  //       },
  //     ],
  //   },
  // ];

  useEffect(() => {
    console.log("p_data", p_data);
  }, [p_data]);

  useEffect(() => {
    // console.log("p_data", p_data);
  }, []);

  return (
    <>
      <div className="FlexRow">asd</div>
      {true ? (
        <div
          style={{
            height: "800px",
            width: "100%",
            backgroundColor: "white",
            // borderBottom: "10rem",
            color: "black",
          }}
        >
          <ResponsiveLine
            data={p_data}
            margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 90,
              legend: "date",
              legendOffset: 0,
              legendPosition: "middle",
              truncateTickAt: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "hour usage",
              legendOffset: -40,
              legendPosition: "middle",
              truncateTickAt: 0,
            }}
            colors={{ scheme: "category10" }}
            lineWidth={3}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={3}
            pointBorderColor={{ from: "serieColor" }}
            enablePointLabel={true}
            pointLabel="data.y"
            pointLabelYOffset={-12}
            areaBlendMode="screen"
            areaBaselineValue={100}
            areaOpacity={0.25}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: -1,
                translateY: -44,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      ) : (
        "loading..."
      )}
    </>
  );
};

export default Chart_Line;
